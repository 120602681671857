<template>
  <div class="ScreeningsUpdateSamples">
    <CloseButton />

    <div class="TitleWrap">
      <span class="PageTitle">
        {{ mixWB('SAMPLING') }} ({{ this.samplesStatus.done }}/{{ this.samplesStatus.total }})
      </span>
      <span
        v-if="currentScreeningSelectedFilterUnitID"
        class="UnitFilterText">{{ mixWB('YOU_ONLY_SEE_FOR_ONE_UNIT') }}</span>
    </div>

    <div class="SamplesListWrap">
      <!-- Auto assessments -->
      <template v-if="listOfSamples.autoAssessments.length">
        <span class="SectionTitle">
          {{ mixWB('AUTOMATIC_ASSESSMENTS') }} ({{ listOfSamples.autoAssessments.length }})
        </span>
        <SampleItem
          v-for="(item, index) in listOfSamples.autoAssessments"
          :key="item.sample.id"
          :type="item.type"
          :sample="item.sample"
          :sampleIndex="index + 1"
          :nextSampleNumber="nextSampleNumber"
          @floor-plan-selected="onFloorPlanSelected"
          @new-state-selected="onNewStateSelected"
          @new-image-selected="onNewImageSelected" />
      </template>

      <!-- Outside -->
      <span class="SectionTitle">
        {{ mixWB('OUTSIDE') }} ({{ listOfSamples.outside.length }})
      </span>
      <NoSamplesText v-if="!listOfSamples.outside.length" />
      <SampleItem
        v-for="(item, index) in listOfSamples.outside"
        :key="item.sample.id"
        :type="item.type"
        :sample="item.sample"
        :nextSampleNumber="nextSampleNumber"
        :sampleIndex="listOfSamples.autoAssessments.length + index + 1"
        @floor-plan-selected="onFloorPlanSelected"
        @new-state-selected="onNewStateSelected"
        @new-image-selected="onNewImageSelected" />

      <!-- Inside -->
      <span class="SectionTitle">
        {{ mixWB('INSIDE') }} ({{ listOfSamples.inside.length }})
      </span>
      <NoSamplesText v-if="!listOfSamples.inside.length" />
      <SampleItem
        v-for="(item, index) in listOfSamples.inside"
        :key="item.sample.id"
        :type="item.type"
        :sample="item.sample"
        :nextSampleNumber="nextSampleNumber"
        :sampleIndex="
          listOfSamples.autoAssessments.length + listOfSamples.outside.length + index + 1
        "
        @floor-plan-selected="onFloorPlanSelected"
        @new-state-selected="onNewStateSelected"
        @new-image-selected="onNewImageSelected" />

      <!-- Other -->
      <span class="SectionTitle">
        {{ mixWB('OTHER') }} ({{ listOfSamples.other.length }})
      </span>
      <NoSamplesText v-if="!listOfSamples.other.length" />
      <SampleItem
        v-for="(item, index) in listOfSamples.other"
        :key="item.sample.id"
        :type="item.type"
        :sample="item.sample"
        :nextSampleNumber="nextSampleNumber"
        :sampleIndex="
          listOfSamples.autoAssessments.length
            + listOfSamples.outside.length
            + listOfSamples.inside.length
            + index + 1
        "
        @floor-plan-selected="onFloorPlanSelected"
        @new-state-selected="onNewStateSelected"
        @new-image-selected="onNewImageSelected" />
    </div>

    <NextStepButton
      :title="mixWB('NEXT')"
      :isDisabled="!samplesStatus.isCompleted" />
  </div>
</template>

<script>
import CloseButton from '@/components/ScreeningItems/CloseButton.vue'
import Sample from '@/globals/javascript/models/Sample'
import NoSamplesText from '@/components/SampleItems/NoSamplesText.vue'
import SampleItem from '@/components/SampleItems/SampleItem.vue'
import NextStepButton from '@/components/ScreeningItems/NextStepButton.vue'
import { mapGetters } from 'vuex'
import { sortBy } from 'lodash-es'

export default {
  name: 'ScreeningsUpdateSamples',
  data() {
    return {
      listOfSamples: {},
    }
  },
  computed: {
    ...mapGetters([
      'categories',
      'currentScreeningSelectedFilterUnitID',
      'samplesStatus',
      'screeningSamples',
      'typesWithSamples',
      'nextAssessNumber',
      'screeningRelatedScreenings',
    ]),
    combinedListOfSamples() {
      return this.listOfSamples.outside.concat(this.listOfSamples.inside, this.listOfSamples.other)
    },
    nextSampleNumber() {
      const sampleNumbers = this.screeningSamples.reduce((prev, sample) => {
        if (sample.sampleNumber) {
          prev.push(sample.sampleNumber)
        }
        return prev
      }, []).sort((a, b) => a - b)

      if (!sampleNumbers.length) {
        return 1
      }

      let nextNumber = null
      sampleNumbers.forEach((number, index) => {
        if (nextNumber) {
          return
        }
        if (number !== index + 1) {
          nextNumber = index + 1
        }
      })

      return nextNumber || sampleNumbers[sampleNumbers.length - 1] + 1
    },
    nextEquivalentNumber() {
      const equivalentNumbers = this.screeningSamples.reduce((prev, sample) => {
        if (sample.equivalentNumber) {
          prev.push(sample.equivalentNumber)
        }
        return prev
      }, []).sort((a, b) => a - b)

      if (!equivalentNumbers.length) {
        return 1
      }

      let nextNumber = null
      equivalentNumbers.forEach((number, index) => {
        if (nextNumber) {
          return
        }
        if (number !== index + 1) {
          nextNumber = index + 1
        }
      })

      return nextNumber || equivalentNumbers[equivalentNumbers.length - 1] + 1
    },
  },
  methods: {
    getListOfSamples() {
      const myObject = {
        outside: [],
        inside: [],
        other: [],
        autoAssessments: [],
      }

      this.typesWithSamples.forEach((type) => {
        const category = this.categories.find((x) => x.id === type.categoryID)

        if (
          this.currentScreeningSelectedFilterUnitID
          && !type.unitIDs.includes(this.currentScreeningSelectedFilterUnitID)
        ) {
          return
        }

        // Material auto-assessment sample
        if (type.assessments.assessmentIDs.length) {
          const existingMaterialSample = this.screeningSamples.find(
            (x) => x.typeID === type.id && x.kindOfSample === 'material',
          )
          if (!existingMaterialSample) {
            return
          }

          myObject.autoAssessments.push({
            type,
            sample: existingMaterialSample,
            categoryPosition: 0,
          })
        }

        // Material sample
        if (type.samples.sampleIDs.length) {
          let sample = null
          const existingMaterialSample = this.screeningSamples.find(
            (x) => x.typeID === type.id && x.kindOfSample === 'material',
          )
          if (existingMaterialSample) {
            sample = existingMaterialSample
          }
          else {
            sample = new Sample({
              typeID: type.id,
              kindOfSample: 'material',
            })
          }
          myObject[category.place].push({
            type,
            sample,
            categoryPosition: category.position,
          })
        }

        // Coating sample
        if (type.coating.samples.sampleIDs.length) {
          let sample = null
          const existingCoatingsample = this.screeningSamples.find(
            (x) => x.typeID === type.id && x.kindOfSample === 'coating',
          )
          if (existingCoatingsample) {
            sample = existingCoatingsample
          }
          else {
            sample = new Sample({
              typeID: type.id,
              kindOfSample: 'coating',
            })
          }
          myObject[category.place].push({
            type,
            sample,
            categoryPosition: category.position,
          })
        }

        // Underlining sample
        if (type.underlining.samples.sampleIDs.length) {
          let sample = null
          const existingUnderliningsample = this.screeningSamples.find(
            (x) => x.typeID === type.id && x.kindOfSample === 'underlining',
          )
          if (existingUnderliningsample) {
            sample = existingUnderliningsample
          }
          else {
            sample = new Sample({
              typeID: type.id,
              kindOfSample: 'underlining',
            })
          }
          myObject[category.place].push({
            type,
            sample,
            categoryPosition: category.position,
          })
        }
      })

      myObject.outside = sortBy(myObject.outside, ['categoryPosition', 'type.createdAt.seconds'])
      myObject.inside = sortBy(myObject.inside, ['categoryPosition', 'type.createdAt.seconds'])
      myObject.other = sortBy(myObject.other, ['categoryPosition', 'type.createdAt.seconds'])

      return myObject
    },
    onNewStateSelected({
      sample, status, equivalentSampleID, otherScreeningID,
    }) {
      setTimeout(() => {
        this.saveSample({
          sample,
          status,
          equivalentSampleID,
          otherScreeningID,
        })
      }, equivalentSampleID ? 100 : 0)
    },
    onNewImageSelected({
      sample, imageData, type, equivalentSampleID,
    }) {
      this.saveSample({
        sample,
        closeImage: type === 'close' ? imageData : false,
        awayImage: type === 'away' ? imageData : false,
        equivalentSampleID,
      })
    },
    onFloorPlanSelected({
      sample,
      floorPlanPosition,
      floorPlanUnit,
    }) {
      this.saveSample({
        sample,
        floorPlanUnit,
        floorPlanPosition,
      })
    },
    saveSample({
      sample,
      status,
      closeImage,
      awayImage,
      equivalentSampleID,
      floorPlanUnit,
      floorPlanPosition,
      otherScreeningID = '',
    }) {
      const isNew = !!this.screeningSamples.find((x) => x.id === sample.id)

      if (status) {
        sample.status = status
      }

      if (status === 'not-done') {
        sample.reset()
        this.$store.dispatch('deleteScreeningSample', sample)
        return
      }

      if (sample.status === 'equivalent') {
        let otherSample = null
        if (otherScreeningID) {
          const relatedScreening = this.screeningRelatedScreenings.find(
            (x) => x.data.id === otherScreeningID,
          )
          otherSample = relatedScreening.samples.find((x) => x.id === equivalentSampleID)
        }
        else {
          otherSample = this.screeningSamples.find((x) => x.id === equivalentSampleID)
        }

        const equivalentNumber = sample.equivalentNumber
          && sample.equivalentNumber < this.nextEquivalentNumber
          ? sample.equivalentNumber
          : this.nextEquivalentNumber
        sample.reset(status)
        sample.otherScreeningID = otherScreeningID
        sample.equivalentNumber = equivalentNumber
        sample.equivalentSampleID = equivalentSampleID
        sample.equivalentSampleNumber = otherSample.sampleNumber
        this.$store.dispatch('setScreeningSample', { sample, isNew })
        return
      }

      if (sample.status.includes('skip-')) {
        const assessNumber = sample.assessNumber && sample.assessNumber < this.nextAssessNumber
          ? sample.assessNumber
          : this.nextAssessNumber
        sample.reset(status)
        sample.otherScreeningID = otherScreeningID
        sample.assessNumber = assessNumber
        this.$store.dispatch('setScreeningSample', { sample, isNew })
        return
      }

      sample.otherScreeningID = otherScreeningID

      const setDone = () => {
        if (sample.images.close
          && sample.images.away
          && sample.floorPlanUnit
          && sample.floorPlanPosition) {
          sample.status = 'done'
        }
      }

      if (closeImage) {
        sample.images.close = closeImage
        setDone()
      }

      if (awayImage) {
        sample.images.away = awayImage
        setDone()
      }

      if (floorPlanPosition && floorPlanUnit) {
        sample.floorPlanPosition = floorPlanPosition
        sample.floorPlanUnit = floorPlanUnit
        setDone()
      }

      if (sample.status === 'done') {
        sample.assessNumber = null
        sample.equivalentNumber = null
        sample.equivalentSampleID = null
        sample.equivalentSampleNumber = null

        if (!sample.sampleNumber) {
          sample.sampleNumber = this.nextSampleNumber
        }
      }

      this.$store.dispatch('setScreeningSample', { sample, isNew })
    },
  },
  components: {
    CloseButton,
    NoSamplesText,
    SampleItem,
    NextStepButton,
  },
  created() {
    this.listOfSamples = this.getListOfSamples()
  },
}
</script>

<style lang="stylus" scoped>
  .ScreeningsUpdateSamples
    pageWrap()

  .TitleWrap
    margin-bottom 20px
    .PageTitle
      display block
      font-size 1.125rem
      font-weight bold
      text-transform uppercase
    .UnitFilterText
      display block
      color $color_salmon
      margin-top 5px

  .SamplesListWrap
    margin-left -20px
    margin-right -20px
    margin-bottom 20px

  .SectionTitle
    display block
    margin-top 20px
    text-transform uppercase
    padding-left 20px
    padding-bottom 5px
    border-bottom 1px solid $color_grey_lighter
    &:first-child
      margin-top 0
</style>
