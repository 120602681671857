<template>
  <div class="DotSplitLine"/>
</template>

<script>
export default {
  name: 'DotSplitLine',

}
</script>

<style lang="stylus" scoped>
  .DotSplitLine
    border-bottom 4px solid $color_grey_light
</style>
