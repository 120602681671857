<template>
  <div
    class="SampleItem"
    :class="{
      'StatusDone': sample.status === 'done',
      'StatusEquivalent': sample.status === 'equivalent',
      'StatusSkipped': sample.status.includes('skip-'),
      'AutoAssessment': sample.isAutoAssessment,
    }">
    <!-- Preview image -->
    <div
      class="PreviewImageWrap"
      @click="showTypeImagesDialog = true">
      <MultiImage :image="type.images[0]" />
      <div class="Number">
        <span v-if="sample.getNumber()">{{ sample.getNumber() }}</span>
        <span
          class="CaseNumber"
          v-if="sample.otherScreeningID">{{ sample.getRelatedScreeningCaseNumber() }}</span>
      </div>
      <div
        v-if="sample.kindOfSample === 'coating'"
        class="KindOfIcon">
        <BrushIcon />
      </div>
      <div
        v-if="sample.kindOfSample === 'underlining'"
        class="KindOfIcon">
        <UnderliningIcon />
      </div>
    </div>

    <!-- Info -->
    <div class="Info">
      <span class="Title">
        #{{ sampleIndex }} - {{ type.getTitle({ category: true, details: true }) }}
      </span>
      <span>{{ type.getUnitsList() }}</span>
      <span v-if="type.getPlacesList()">{{ type.getPlacesList() }}</span>
      <span v-if="sample.kindOfSample === 'material'">{{ type.getMaterialSampleList() }}</span>
      <span v-if="sample.kindOfSample === 'coating'">{{ type.getCoatingSampleList() }}</span>
      <span v-if="sample.kindOfSample === 'underlining'">
        {{ type.getUnderliningSampleList() }}
      </span>
      <span v-if="type.description">{{ type.description }}</span>
      <span
        v-if="sample.status.includes('skip-') || sample.isAutoAssessment"
        class="AssessText">
        {{ mixWB('ASSESSED') }}: {{ mixGetSampleStatusWordbook(sample.status) }}
      </span>
    </div>

    <div
      class="RightSide"
      v-if="!sample.isAutoAssessment">
      <template v-if="sample.images.close && sample.images.away && !sample.floorPlanUnit">
        <Button
          class="FloorPlanButton"
          :text="mixWB('FLOOR_PLAN')"
          outlined
          size="small"
          @button-click="showFloorplanSelectDialog = true" />
      </template>
      <template v-else>
        <!-- Close image -->
        <div
          v-if="['not-done', 'done'].includes(sample.status)"
          class="ImageWrap">
          <ImageUploadButton
            v-show="!sample.images.close"
            type="close"
            :autoOpenTrigger="autoOpenCloseImage"
            @image-selected="onNewFile" />
          <div
            v-if="sample.images.close"
            class="ImageThumb">
            <MultiImage
              :image="sample.images.close"
              loadingSize="tiny"
              preview
              enableReupload
              @reupload="onImageReupload('close')" />
          </div>
          <div class="Label">
            <span>{{ mixWB('CLOSE_UP') }}</span>
          </div>
        </div>

        <!-- Away image -->
        <div
          v-if="['not-done', 'done'].includes(sample.status)"
          class="ImageWrap">
          <ImageUploadButton
            v-show="!sample.images.away"
            type="away"
            :autoOpenTrigger="autoOpenAwayImage"
            @image-selected="onNewFile" />
          <div
            v-if="sample.images.away"
            class="ImageThumb">
            <MultiImage
              :image="sample.images.away"
              loadingSize="tiny"
              preview
              enableReupload
              @reupload="onImageReupload('away')" />
          </div>
          <div class="Label">
            <span>{{ mixWB('AWAY') }}</span>
          </div>
        </div>
      </template>

      <!-- Menu -->
      <div class="MenuWrap">
        <SampleItemMenu
          :sample="sample"
          @option-click="onMenuItemClick" />
      </div>
    </div>

    <!-- Type images dialog -->
    <Dialog
      :isShowing="showTypeImagesDialog"
      :useComponent="TypeImages"
      :componentProps="{
        title: type.getTitle({ details: true }),
        type,
      }"
      @close="showTypeImagesDialog = false" />

    <!-- Assess select dialog -->
    <Dialog
      :isShowing="showAssessSelectDialog"
      :useComponent="SelectAssessment"
      :componentProps="{
        selectedID: sample.status || '',
        materialID: categoryType ? categoryType.materialID : '',
      }"
      @assess-select="onAssessSelect"
      @close="showAssessSelectDialog = false" />

    <!-- Equivalent select dialog -->
    <Dialog
      :isShowing="showEquivalentSelectDialog"
      :useComponent="SelectEquivalent"
      :componentProps="{
        selectedID: sample.equivalentSampleID || '',
      }"
      @equivalent-select="onEquivalentSelect"
      @close="showEquivalentSelectDialog = false" />

    <!-- Floor plan select dialog -->
    <Dialog
      :isShowing="showFloorplanSelectDialog"
      :useComponent="SelectFloorPlan"
      :componentProps="{
        type,
        sample,
        nextSampleNumber,
      }"
      size="fullScreen"
      @floor-plan-select="onFloorPlanSelect"
      @close="showFloorplanSelectDialog = false" />
  </div>
</template>

<script>
import MultiImage from '@/components/Images/MultiImage.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import TypeImages from '@/components/SampleItems/Dialog/TypeImages.vue'
import SampleItemMenu from '@/components/SampleItems/SampleItemMenu.vue'
import ImageUploadButton from '@/components/Buttons/ImageUploadButton.vue'
import SelectAssessment from '@/components/SampleItems/Dialog/SelectAssessment.vue'
import SelectEquivalent from '@/components/SampleItems/Dialog/SelectEquivalent.vue'
import SelectFloorPlan from '@/components/SampleItems/Dialog/SelectFloorPlan.vue'
import { mapGetters } from 'vuex'
import EventBus from '@/EventBus'
import { minImageSize, prepareImage } from '@/globals/javascript/_util/images'
import BrushIcon from '@/assets/svg/brush.svg?inline'
import UnderliningIcon from '@/assets/svg/underlining.svg?inline'
import Button from '@/components/Buttons/Button.vue'

export default {
  name: 'SampleItem',
  props: {
    type: {
      type: Object,
      required: true,
    },
    sample: {
      type: Object,
      required: true,
    },
    sampleIndex: {
      type: Number,
      required: true,
    },
    nextSampleNumber: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      minImageSize,
      prepareImage,
      TypeImages,
      SelectAssessment,
      SelectEquivalent,
      SelectFloorPlan,
      showTypeImagesDialog: false,
      showAssessSelectDialog: false,
      showEquivalentSelectDialog: false,
      showFloorplanSelectDialog: false,
      autoOpenCloseImage: false,
      autoOpenAwayImage: false,
      images: {
        close: null,
        away: null,
      },
    }
  },
  computed: {
    ...mapGetters([
      'categoryTypes',
      'imageUploadFolder',
      'screeningSamples',
    ]),
    categoryType() {
      return this.categoryTypes.find((x) => x.id === this.type.type.typeID)
    },
  },
  methods: {
    getBasePath() {
      return `${ this.imageUploadFolder }Samples/`
    },
    onNewFile({ file, type }) {
      // Create image object
      const imageObject = this.prepareImage({
        basePath: this.getBasePath(),
        file,
        postFix: type,
        minSize: this.minImageSize,
      })

      // Save image sizes for upload
      this.$store.dispatch('uploadImage', {
        imageObject,
        file,
        place: `${ this.mixWB('SAMPLES_2') }: ${ this.type.getTitle({ details: true }) }`,
        makeSquared: true,
      })

      // Save image
      this.$emit('new-image-selected', {
        sample: this.sample,
        imageData: imageObject,
        type,
      })

      if (type === 'away' && !this.sample.floorPlanUnit) {
        this.showFloorplanSelectDialog = true
      }
    },
    onImageReupload(type) {
      requestAnimationFrame(() => {
        if (type === 'close') {
          this.autoOpenCloseImage = true
        }

        if (type === 'away') {
          this.autoOpenAwayImage = true
        }

        requestAnimationFrame(() => {
          this.autoOpenCloseImage = false
          this.autoOpenAwayImage = false
        })
      })
    },
    onMenuItemClick(status) {
      // Check for assesss
      if (status === 'assess') {
        this.showAssessSelectDialog = true
        return
      }

      // Check for equivalent
      if (status === 'equivalent') {
        this.showEquivalentSelectDialog = true
        return
      }

      if (status === 'set-marker-floorplan') {
        this.showFloorplanSelectDialog = true
        return
      }

      if (status === 'reset') {
        const answer = window.confirm(this.mixWB('ARE_YOU_SURE'))
        if (answer) {
          this.resetSamples(this.sample.id)
        }
        return
      }
    },
    onAssessSelect(status) {
      this.showAssessSelectDialog = false

      if (this.sample.sampleNumber) {
        const answer = window.confirm(`${
          this.mixWB('THIS_WILL_RESET_ITS_SAMPLE_NUMBER')
        }. ${
          this.mixWB('ARE_YOU_SURE')
        }`)
        if (!answer) {
          return
        }

        this.resetSamples()
      }

      this.$emit('new-state-selected', {
        sample: this.sample,
        status: status || 'not-done',
      })
    },
    onEquivalentSelect({ equivalentSampleID, otherScreeningID }) {
      this.showEquivalentSelectDialog = false

      if (this.sample.sampleNumber) {
        const answer = window.confirm(`${
          this.mixWB('THIS_WILL_RESET_ITS_SAMPLE_NUMBER')
        }. ${
          this.mixWB('ARE_YOU_SURE')
        }`)
        if (!answer) {
          return
        }

        this.resetSamples()
      }

      this.$emit('new-state-selected', {
        sample: this.sample,
        status: equivalentSampleID ? 'equivalent' : 'not-done',
        equivalentSampleID,
        otherScreeningID,
      })
    },
    onFloorPlanSelect({ floorPlanPosition, floorPlanUnit }) {
      this.showFloorplanSelectDialog = false
      this.$emit('floor-plan-selected', {
        sample: this.sample,
        floorPlanPosition,
        floorPlanUnit,
      })
    },
    resetSamples(sampleID) {
      const idsToBeReset = []

      if (sampleID) {
        idsToBeReset.push(sampleID)
      }

      // Add equivalent IDs to list
      if (this.sample.sampleNumber) {
        this.screeningSamples.forEach((sample) => {
          if (sample.equivalentSampleID === this.sample.id) {
            idsToBeReset.push(sample.id)
          }
        })
      }

      if (idsToBeReset.length) {
        EventBus.$emit('reset-samples', idsToBeReset)
      }
    },
    onResetSamples(idsToBeReset) {
      if (!idsToBeReset.includes(this.sample.id)) {
        return
      }
      this.sample.reset()
      this.$store.dispatch('deleteScreeningSample', this.sample)
    },
  },
  components: {
    MultiImage,
    Dialog,
    SampleItemMenu,
    ImageUploadButton,
    BrushIcon,
    UnderliningIcon,
    Button,
  },
  created() {
    EventBus.$on('reset-samples', this.onResetSamples)
  },
  destroyed() {
    EventBus.$off('reset-samples', this.onResetSamples)
  },
}
</script>

<style lang="stylus" scoped>
  .SampleItem
    position relative
    display flex
    padding 5px
    background-color $color_grey_lightest
    border-bottom 1px solid $color_grey_lighter
    &.StatusDone
      background-color $color_primary_lightest
    &.StatusEquivalent
      background-color $color_purple_lightest
    &.StatusSkipped
      background-color $color_yellow_lightest
    &.AutoAssessment
      background-color $color_yellow_lightest
    span
      display block

  .PreviewImageWrap
    position relative
    flex-shrink 0
    box(60px)
    cursor pointer
    margin-right 10px
    .Number
      position absolute
      bottom 0
      width 100%
      flex-shrink 0
      margin-right 10px
      flex-direction column
      flex-center-children()
      padding 2.5px
      .StatusDone &
        background-color rgba($color_primary, 0.7)
      .StatusEquivalent &
        background-color rgba($color_purple, 0.8)
      .StatusSkipped &
        background-color rgba($color_yellow, 0.7)
      .AutoAssessment &
        background-color rgba($color_yellow, 0.7)
      span
        text-align center
        line-height 1
        display block
        font-size 0.75rem
        font-weight bold
        &.CaseNumber
          font-size 0.5rem
          line-height 1.5
    .KindOfIcon
      position absolute
      top 0
      right 0
      box(20px)
      padding 2.5px
      background-color rgba($color_blue_dark, 0.7)
      svg
        fill #fff

  .Info
    flex-grow 2
    span
      font-size 0.665rem
    .Title
      font-size 0.75rem
      text-transform uppercase
      font-weight bold
    .AssessText
      padding-top 5px

  .RightSide
    flex-shrink 0
    display flex
    align-items center
    justify-content flex-end
    .FloorPlanButton
      min-width: (50 + 50 + 5)px // Same width as the two images.
    .ImageWrap
      position relative
      box(50px)
      margin-left 5px
      flex-shrink 0
      .Label
        pointer-events none
        position absolute
        bottom 10%
        left 0
        width 100%
        font-size 0.665rem
        text-align center
        background-color rgba($color_grey_dark, 0.75)
        span
          color #fff
    .ImageThumb
      border 1px solid $color_grey_light
    .MenuWrap
      box(40px)
      margin-left 5px
      flex-shrink 0
</style>
