<template>
  <div
    class="Dots"
    @click="onClick">
    <span />
  </div>
</template>

<script>

export default {
  name: 'Dots',
  props: {
    onClick: {
      type: Function,
      required: false,
      default: () => null,
    },
  },
}
</script>

<style lang="stylus" scoped>
  .Dots
    box(100%)
    display flex
    flex-direction column
    justify-content center
    align-items center
    cursor pointer
    span
    &:before
    &:after
      circle(5px)
      background-color $color_grey_light
      margin 1px 0px
    &:before
    &:after
      content ''
</style>
