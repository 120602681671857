<template>
  <div
    :class="rootClasses"
    class="DotMenuItem"
    @click="onDotItemClick">
    <div
      v-if="icon"
      class="IconWrap">
      <component :is="iconToUse"/>
    </div>
    <span>{{ text }}</span>
  </div>
</template>

<script>
import LockIcon from '@/assets/svg/lock.svg?inline'
import SplitIcon from '@/assets/svg/split.svg?inline'
import NoneIcon from '@/assets/svg/none.svg?inline'
import BothIcon from '@/assets/svg/both-ways.svg?inline'
import ArrowRightIcon from '@/assets/svg/arrow-right.svg?inline'

export default {
  name: 'DotMenuItem',
  props: {
    text: {
      type: String,
      required: true,
    },
    itemID: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: '', // '' | 'red' | 'yellow'
    },
    size: {
      type: String,
      required: false,
      default: 'normal', // 'normal' | 'small'
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      LockIcon,
      SplitIcon,
      NoneIcon,
      BothIcon,
      ArrowRightIcon,
    }
  },
  computed: {
    rootClasses() {
      return {
        ColorRed: this.color === 'red',
        ColorYellow: this.color === 'yellow',
        SizeNormal: this.size === 'normal',
        SizeSmall: this.size === 'small',
        HasIcon: this.icon,
        LeftArrowIcon: this.icon === 'left',
        BothIcon: this.icon === 'both',
      }
    },
    iconToUse() {
      if (this.icon === 'lock') {
        return this.LockIcon
      }
      if (this.icon === 'split') {
        return this.SplitIcon
      }
      if (this.icon === 'none') {
        return this.NoneIcon
      }
      if (this.icon === 'both') {
        return this.BothIcon
      }
      if (this.icon === 'left') {
        return this.ArrowRightIcon
      }
      if (this.icon === 'right') {
        return this.ArrowRightIcon
      }
      return false
    },
  },
  methods: {
    onDotItemClick() {
      this.$emit('dot-item-click', this.itemID)
    },
  },
}
</script>

<style lang="stylus" scoped>
  .DotMenuItem
    display flex
    align-items center
    padding 12.5px 10px
    width 100%
    border-bottom 1px solid $color_grey_light
    cursor pointer
    .IconWrap
      box(20px)
      margin-right 10px
    &.BothIcon
      .IconWrap
        transform rotate(90deg)
    &.LeftArrowIcon
      .IconWrap
        transform rotate(180deg)
    span
      truncated()

    &:last-child
      border-bottom none
    &.ColorRed
      span
        color red
    &.ColorYellow
      span
        color $color_yellow_dark
    &.SizeNormal
      span
        font-size 1rem
    &.SizeSmall
      span
        font-size 0.875rem
    &.HasIcon
      padding 10px 10px
</style>
