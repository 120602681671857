<template>
  <div class="NoSamplesText">
    <span>{{ mixWB('NO_SAMPLES_NEEDED_HERE') }}</span>
  </div>
</template>

<script>
export default {
  name: 'NoSamplesText',

}
</script>

<style lang="stylus" scoped>
  .NoSamplesText
    padding 10px 20px
    span
      font-style italic
</style>
