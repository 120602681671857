var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"SelectFloorPlan"},[(!_vm.selectedFloorPlan)?_c('ErrorText',{attrs:{"text":_vm.mixWB('NO_FLOOR_PLANS_UPLOADED_MARKER')}}):[(_vm.selectedFloorPlan)?_c('MultiImage',{attrs:{"keepSquare":false,"image":_vm.selectedFloorPlan.floorPlan},on:{"on-load":_vm.imageIsLoaded}},[(_vm.imageLoaded)?_c('div',_vm._g({ref:"container",staticClass:"MarkerContainer"},{
          touchmove: _vm.handleDragMove,
          mousemove: _vm.handleDragMove,
          touchstart: _vm.handleDragStart,
          touchend: _vm.handleDragEnd,
          mousedown: _vm.handleDragStart,
          mouseup: _vm.handleDragEnd,
        }),[_c('div',_vm._g({staticClass:"Marker CurrentMarker",class:{ IsDragging: _vm.isDragging },style:(_vm.getMarkerPositionStyle())},{
          }),[_vm._v(" "+_vm._s(_vm.sample.getNumber() || ("P" + _vm.nextSampleNumber))+" ")]),_vm._l((_vm.otherSamplePositions),function(otherSample){return _c('div',{key:otherSample.id,staticClass:"Marker OtherMarker",style:({
            top: ((otherSample.floorPlanPosition.y) + "%"),
            left: ((otherSample.floorPlanPosition.x) + "%"),
          })},[_vm._v(" "+_vm._s(otherSample.getNumber())+" ")])}),_c('div',{staticClass:"MarkerPlaceholder",class:{ Hidden: _vm.sampleMarkerPosition && _vm.sampleMarkerPosition.x !== null }})],2):_vm._e()]):_vm._e(),_c('div',{staticClass:"Actions"},[(_vm.availableFloorPlansOptions.length === 1)?_c('div',{staticClass:"FloorSelectSingleOption"},[_vm._v(" "+_vm._s(_vm.availableFloorPlansOptions[0].name)+" ")]):_c('Dropdown',{staticClass:"FloorSelect",attrs:{"name":"selected-unit","options":_vm.availableFloorPlansOptions,"value":_vm.selectedUnit,"backgroundColor":"white"},on:{"on-update":_vm.onSelectFloorPlanUnit}}),_c('Button',{attrs:{"text":_vm.mixWB('SAVE'),"isDisabled":!_vm.sampleMarkerPosition || !_vm.sampleMarkerPosition.x},on:{"button-click":_vm.onSave}})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }