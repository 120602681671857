<template>
  <div class="Select">
    <select @change="onOptionChange">
      <!-- Groups -->
      <template v-if="optionGroups.length">
        <optgroup
          :label="group.title"
          v-for="group in optionGroups"
          :key="group.title">
          <option
            v-for="item in group.options"
            :key="item.id"
            :selected="item.id === selectedID"
            :value="item.id">{{ item.text }}</option>
        </optgroup>
      </template>

      <!-- No groups -->
      <template v-else>
        <option
          v-for="item in options"
          :key="item.id"
          :selected="item.id === selectedID"
          :value="item.id">{{ item.text }}</option>
      </template>
    </select>

    <div class="ArrowWrap">
      <AngleRightIcon />
    </div>
  </div>
</template>

<script>
import AngleRightIcon from '@/assets/svg/angle-right.svg?inline'

export default {
  name: 'Select',
  props: {
    name: {
      type: String,
      required: true,
    },
    selectedID: {
      type: String,
      required: true,
    },
    optionGroups: {
      type: Array,
      required: false,
      default: () => [],
    },
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  methods: {
    onOptionChange({ target }) {
      const optionID = target.options[target.selectedIndex].value
      this.$emit('change', { name: this.name, value: optionID })
    },
  },
  components: {
    AngleRightIcon,
  },
}
</script>

<style lang="stylus" scoped>
  .Select
    position relative
    box-shadow $box_shadow_1
    select
      display block
      height 40px
      padding-left 11px
      font-size 1.125rem
      width 100%
      border 1px solid $color_grey_light
      background-color #fff
      appearance none
      border-radius 0
      outline none
    optgroup
      text-transform uppercase
      font-size 0.875rem
    option
      font-size 0.875rem

  .ArrowWrap
    box(16px)
    position absolute
    top 14px
    right 14px
    transform rotateZ(90deg)
    pointer-events none
    svg
      fill $color_grey
</style>
