<template>
  <label class="ImageUploadButton">
    <input
      ref="UploadInput"
      name="upload-images"
      type="file"
      accept="image/*"
      capture="true"
      @change="onImagesSelected" />
    <div class="TopPadding"/>
    <div class="IconWrap">
      <CameraIcon />
    </div>
  </label>
</template>

<script>
import CameraIcon from '@/assets/svg/camera.svg?inline'

export default {
  name: 'ImageUploadButton',
  props: {
    type: {
      type: String,
      required: true,
    },
    autoOpenTrigger: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    autoOpenTrigger(to) {
      if (to) {
        this.$refs.UploadInput.click()
      }
    },
  },
  methods: {
    onImagesSelected(event) {
      const { files } = event.target

      if (!files || !files[0]) {
        return
      }

      this.$emit('image-selected', {
        file: files[0],
        type: this.type,
      })
    },
  },
  components: {
    CameraIcon,
  },
}
</script>

<style lang="stylus" scoped>
  .ImageUploadButton
    box(100%)
    display block
    position relative
    border 1px solid lighten($color_grey_lighter, 25%)
    input
      box(0px)
      overflow hidden
    .IconWrap
      box(100%)
      padding 25%
      position absolute
      top 0
      left 0
</style>
